.Issue {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  user-select: none;
  font-family: 'GmarketSansMedium', sans-serif;

  h1 {
    font-size: 5rem;
    display: flex;
    gap: 1rem;
    align-items: center;
    margin-bottom: 0;

    span {
      color: red;
    }
  }

  p {
    color: black;
    font-size: 2.4rem;
    margin-top: 1rem;
    text-align: center;
  }

  .form {
    width: 50rem;
    input, button {
      padding: 1rem;
      font-size: 2.5rem;
      margin-bottom: 1rem;
      border-radius: 1rem;
      font-family: 'GmarketSansMedium', sans-serif;
    }

    input {
      width: 47.8rem;
    }

    button {
      width: 50rem;
    }
  }

  .showNum {
    font-size: 20rem;
    font-family: sans-serif;
    text-align: center;
  }
}