html, body {
  font-size: 17px;
  height: 100%;
}

.Board {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  user-select: none;
  font-family: 'GmarketSansMedium', sans-serif;

  .background-image {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    background-image: url("coin-8839127_1280.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    filter: blur(0.5rem) brightness(50%);
  }

  h1 {
    font-size: 5rem;
    color: #fad105;
    text-shadow: 0.1rem 0.2rem 0.2rem #bda21e;
    display: flex;
    gap: 1rem;
    align-items: center;
    margin-bottom: 0;

    img {
      width: 5rem;
      height: 5rem;
    }
  }

  p {
    color: #e5e0c8;
    font-size: 2.4rem;
    margin-top: 1rem;
    text-align: center;
  }

  input {
    width: 50rem;
    padding: 1rem;
    font-size: 2.5rem;
    margin-bottom: 1rem;
    border-radius: 1rem;
    font-family: 'GmarketSansMedium', sans-serif;
  }

  table {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    tbody {
      &.background {
        position: absolute;
        background-color: black;
        width: 66rem;
        height: 60rem;
        z-index: -1;
        filter: opacity(50%);
        border-radius: 4rem;
      }

      &.header {
        padding-top: 1.5rem;

      }

      &.board {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        width: 64rem;
        height: 50rem;
        overflow-x: hidden;
        overflow-y: scroll;

        &::-webkit-scrollbar {
          width: 2rem;
          height: 2rem;
        }

        &::-webkit-scrollbar-thumb {
          background-color: #153f5d;
          border-radius: 0.7rem;
          background-clip: padding-box;
          border: 2px solid transparent;
        }

        &::-webkit-scrollbar-track {
          background-color: #a9a9a9;
          border-radius: 0.7rem;
        }
      }

      tr {
        display: flex;
        justify-content: flex-start;
        gap: 0;

        th, td {
          text-align: center;
          width: 15rem;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 0.5rem;

          &.reward {
            width: 40rem;
            justify-content: left;
          }
        }

        th {
          font-size: 3rem;
          color: #d2af03;
        }

        td {
          font-size: 2.5rem;
          color: #e5e0c8;

          img {
            width: 2.5rem;
            height: 2.5rem;
            padding-bottom: 0.5rem;
          }
        }
      }
    }
  }
}

@font-face {
  font-family: 'GmarketSansMedium';
  src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/GmarketSansMedium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}